.whatsapp_float {
  position: fixed;
  z-index: 99999;
  bottom: 16px;
  left: 16px;
}

.whatsapp_float_btn {
  border-radius: 63%;
  height: 60px;
  width: 60px;
}

.show-menu {
  display: none;
}

.css-o69gx8-MuiCardMedia-root {
  width: none;
}

.head1 {
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
}
.head2 {
  color: white;
  font-weight: 100;
  font-size: 11px;
  line-height: 24px;
}
.X {
  margin: auto;
  padding: 1% 2%;
  max-width: 1500px;
}

li {
  text-align: justify;
}
.SG {
  margin: 0;
  padding: 0;
  text-align: center;
}
.SG .sgLi {
  min-width: 45%;
  margin: 2% 0.35%;
  display: inline-flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.SG .sgLi:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.SG .box {
  width: 100%;
  height: 100vh;
  padding: 1% 2%;
  background: #fff;
  min-height: 200px;
  max-height: 220px;
  box-sizing: border-box;
}
.df {
  list-style-type: disc;
}
.s1 {
  list-style-type: square;
}
.s2 {
  list-style-type: circle;
}
.s3 {
  list-style-type: decimal;
}
.s4 {
  list-style-type: decimal-leading-zero;
}
.s5 {
  list-style-type: lower-alpha;
}
.s6 {
  list-style-type: upper-alpha;
}
.s7 {
  list-style-type: lower-roman;
}
.s8 {
  list-style-type: upper-roman;
}
.s9 {
  list-style-type: lower-greek;
}
.s10 {
  list-style-type: georgian;
}
.s11 {
  list-style-type: hebrew;
}
.s12 {
  list-style-type: hiragana;
}
.s13 {
  list-style-type: hiragana-iroha;
}
.s14 {
  list-style-type: katakana;
}
.s15 {
  list-style-type: katakana-iroha;
}
.s16 {
  list-style-type: cjk-ideographic;
}
.s17 {
  list-style-image: url(//goo.gl/L3tqpe);
}
.s18 {
  list-style: none;
}
.s18 li:before {
  content: "";
  width: 20px;
  height: 20px;
  margin-right: 15px;
  display: inline-block;
  background: url(//goo.gl/lcPSVD);
  background-position: 50%;
}
.s19 {
  list-style: none;
}
.s19 li:before {
  content: "\f0a9";
  margin-right: 15px;
  font-family: FontAwesome;
}

@media (max-width: 970px) {
  .SG .sgLi {
    width: 180px;
  }
}
@media (max-width: 425px) {
  .SG .sgLi {
    width: 100%;
  }
}

.task {
  background-color: red;
}

.rs-slider.slider-style4 .slider .slider-item {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
/* .rs-breadcrumbs {
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

@media (min-width: 970px) {
.rs-breadcrumbs {
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
}